import React from 'react';



function Footer() {
    return(
        <>
           
{/* footer */}

        </>
    );
}

export default Footer;