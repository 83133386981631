
import React, { useEffect, useState } from 'react';

import axios from 'axios';
import Userheader from '../inc/Userheader';

import Modal from 'react-modal';

import Supportmail from '../inc/Supportmail';

function ExamscoreContent() { 

    const [scorelist, setScorelist] = useState([]);

    async function score_list() {
        var fd=new FormData();
        fd.append("user_app_id",localStorage.getItem("user_app_id"));
        var resp=await axios.post("https://skillupaviation.co.in/api/showuserscore",fd);
        var data=resp.data;
        setScorelist(data.obj);
    }

    const [examid,setExamid]=useState("");

    const [score, setScore] = useState("");
    async function showexamdata(score_id) {
        openModal();
        var fd=new FormData();
        fd.append("score_id",score_id);
        var resp=await axios.post("https://skillupaviation.co.in/api/showexamdata",fd);
        var data=resp.data;
        setScore(data.obj);
        console.log(score)
    }

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgroup={
        margin: '10px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    useEffect(() => {
        score_list();

        if (localStorage.getItem('examid')!=null) {
            localStorage.removeItem('examid');
            localStorage.removeItem("enddatetime");
            localStorage.removeItem("userpaymentid");
        }
    }, []);

    return(
        <>
<div id="content">   
    <div className="p-4 contentWrapper">
    	<div className="row">
        	<div className="col-lg-12 dasboard-wrapper">
            <h4 className="mb-3">Exam Score</h4>

            <Userheader />

            
            <div className="bg-white p-4">
                <div className="row text-white font-weight-bold">
                    <div className="col-md-12">
                    <div className='table-responsive'>
                    <table class="table table-hover">
                        <thead>
                        <tr>
                            <th width={50}>SL</th>
                            <th>Exam Name</th>
                            <th>Total Marks</th>
                            <th>Pass Mark</th>
                            <th>Mark Obtained</th>
                            <th className='text-center'>Result</th>
                            {/* <th>Scores</th> */}
                            <th width={150} className='text-center'>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                            {scorelist.map((e,i=0)=>
                            <tr key={e.score_id}>
                                <td>{++i}</td>
                                <td>{e.exam_name}</td>
                                <td>{e.total_no_qst}</td>
                                <td>{e.exam_passed}</td>
                                <td>{e.exam_score}</td>
                                <td className='text-center'>{e.exam_score>=parseInt(e.exam_passed)?<span className='text-success text-uppercase'>Qualified</span>:<span className='text-danger text-uppercase'>Disqualified</span>}</td>
                                {/* <td>{e.exam_percent}</td> */}
                                <td className='text-center'>
                                    <button className="btn btn-primary" onClick={()=>{
                                        showexamdata(e.score_id);
                                        setExamid(e.exam_id);
                                    }}>View Score</button>    
                                </td>
                            </tr>
                            )}
                    
                        </tbody>
                    </table>
                    </div>

                    {/* <h2 className="text-danger text-center">No Exam Score Found</h2> */}

                    </div>                                        
                </div>                    
            </div>

                
                
            </div>


        </div>


        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel=""
        >

            <div className="main">
                <div className="loaderWrapper">
                <h5 className='text-center mt-4 text-primary'>Exam Score</h5>
                    <table className="table table-hover fw-bold">

                        <tbody>
                            <tr>
                                <td>Exam Name</td>
                                <td>: {score.exam_name}</td>                              
                            </tr>
                            <tr>
                                <td>Number of Questions</td>
                                <td>: {score.exam_total_qst}</td>                              
                            </tr>
                            <tr>
                                <td>Total Marks</td>
                                <td>: {score.exam_total_qst}</td>                              
                            </tr>
                            <tr>
                                <td>Pass Marks</td>
                                <td>: {score.exam_passed}</td>                              
                            </tr>
                            <tr>
                                <td>Correct Total</td>
                                <td>: {score.no_of_correct}</td>                              
                            </tr>
                            <tr>
                                <td>Wrong Total</td>
                                <td>: {score.no_of_wrong}</td>                              
                            </tr>
                            <tr>
                                <td>Skip Total</td>
                                <td>: {score.not_answered}</td>                              
                            </tr>
                            {examid!=1?
                            <tr>
                                <td>Negative Mark Total</td>
                                <td>: (-) {score.negetive_mark}</td>                              
                            </tr>
                            :
                            ''
                            }
                            <tr>
                                <td>Mark Obtained</td>
                                <td>: {score.exam_score}</td>                              
                            </tr>
                            <tr>
                                <td>RESULT</td>
                                <td>: {score.exam_score>=parseInt(score.exam_passed)?<span className='text-success text-uppercase'>Qualified</span>:score.exam_score<score.exam_passed?<span className='text-danger text-uppercase'>Disqualified</span>:''}</td>                              
                            </tr>
                        </tbody>
                    </table> 
                </div>
                <div className="row">
                    <div className="col-md-12 text-center mt-0">
                        <input type="button" className="ml-2 btn btn-danger text-center" value="Close" onClick={()=>{
                            closeModal();
                        }} />
                    </div>
                </div>
            </div>                        

        </Modal>
    	
    
       
      
    </div>
  </div>

        </>
    );
}

export default ExamscoreContent;