import React  from 'react';

import DashboardContent from "../content/DashboardContent";

import Navbar from "../inc/Navbar";
import Sidebar from "../inc/Sidebar";
import Footer from "../inc/Footer";

function Dashboard() {
    return(
        <>
        <Navbar />       
        <div class="wrapper">
            
            <Sidebar />
            <DashboardContent />
        </div>
        <Footer />        
        </>
    );
}

export default Dashboard;