import React from 'react';
import {Link} from 'react-router-dom';
import dateFormat from 'dateformat';

function Sidebar() {
    return(
        <>
           
<nav id="sidebar">
    <div class="sidebar-header">
      <p>Today is {dateFormat(new Date(), "dddd, mmmm dd, yyyy")}</p>
    </div>
    <ul class="list-unstyled components">
    	<li class="active">
            <Link to="/dashboard"><i class="glyphicon glyphicon-dashboard"></i>Dashboard</Link>
        </li>
        <li>
            <Link to="/my-profile"><i class="glyphicon glyphicon-dashboard"></i>My Profile</Link>
        </li>

        <li>
            <Link to="/payment-history"><i class="glyphicon glyphicon-dashboard"></i>Payment History</Link>
        </li>

        <li>
            <Link to="/exam-score"><i class="glyphicon glyphicon-dashboard"></i>Exam Score</Link>
        </li>
      
    
      
      
      
      
      
      {/* <li> <a href="#pagesSubmenu" data-toggle="collapse" aria-expanded="false"><i class="glyphicon glyphicon-list-alt"></i>Pages</a>
        <ul class="collapse list-unstyled" id="pagesSubmenu">
          <li><a href="all_pages.html"><i class="glyphicon glyphicon-chevron-right"></i>All Pages</a></li>
          <li><a href="add_page.html"><i class="glyphicon glyphicon-chevron-right"></i>Add Pages</a></li>          
        </ul>
      </li>
      <li><a href="#homeBanner" data-toggle="collapse" aria-expanded="false"><i class="glyphicon glyphicon-camera"></i>Home Banner</a>
      	<ul class="collapse list-unstyled" id="homeBanner">
          <li><a href="banner_management.html"><i class="glyphicon glyphicon-chevron-right"></i>Management</a></li>
          <li><a href="add_banner.html"><i class="glyphicon glyphicon-chevron-right"></i>Add New</a></li>
          <li><a href="banner_settings.html"><i class="glyphicon glyphicon-chevron-right"></i>Settings</a></li>          
        </ul>
      	
      </li>
      <li><a href="gallery_management.html"><i class="glyphicon glyphicon-picture"></i>Gallery Management</a> </li>
      <li><a href="testimonial.html"><i class="glyphicon glyphicon-comment"></i>Testimonial</a> </li>      
      <li><a href="contact.html"><i class="glyphicon glyphicon-earphone"></i>Contact</a> </li>
            
      <li><a href="menus.html"><i class="glyphicon glyphicon-envelope"></i>Menus</a></li>
      <li><a href="themes.html"><i class="glyphicon glyphicon-th-large"></i>Themes</a> </li>
      <li><a href="settings.html"><i class="glyphicon glyphicon-cog"></i>Settings</a> </li> */}
    </ul>
  </nav>

        </>
    );
}

export default Sidebar;