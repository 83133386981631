import React  from 'react';

import IndexContent from "../content/IndexContent";

// import Footer from "../inc/Footer";

function Home() {
    return(
        <>
           
            <IndexContent/>
            {/* <Footer />         */}
        </>
    );
}

export default Home;