
import React from 'react';
import {BrowserRouter, Routes,Route} from 'react-router-dom';

import Home from './layout/Home';
import Dashboard from './layout/Dashboard';
import Myprofile from './layout/Myprofile';
import Paymenthistory from './layout/Paymenthistory';
import Examscore from './layout/Examscore';
import Startexamdetails from './layout/Startexamdetails';
import Exam from './layout/Exam';


import About from './layout/About';



import './App.css';
import ChangePassword from './layout/ChangePassword';

function App() {
  return (
  <>
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/dashboard' element={<Dashboard />} />
        <Route exact path='/my-profile' element={<Myprofile />} />
        <Route exact path='/payment-history' element={<Paymenthistory />} />
        <Route exact path='/exam-score' element={<Examscore />} />
        <Route exact path='/startexamdetails' element={<Startexamdetails />} />
        <Route exact path='/exam' element={<Exam />} />
        <Route exact path='/change-password' element={<ChangePassword />} />
        
        

        

        

        
        <Route exact path='/about' element={<About />} />
      </Routes>
    </BrowserRouter>
  </>
  );
}

export default App;
