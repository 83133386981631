
import React, { useEffect, useState, useRef } from 'react';

import axios from 'axios';

import {useNavigate} from "react-router-dom";

import dateFormat from 'dateformat';

import Modal from 'react-modal';

import $ from "jquery";

function ExamContent() {  
    let navigate = useNavigate();

    let [examarr, setExamarr] = useState([]);
    let [examname, setExamname] = useState("");
    let [totalqst, setTotalqst] = useState("");

    let ansarr=[];

    async function exam_details() {
        // console.log(localStorage.getItem("enddatetime")+' , '+localStorage.getItem("examid"));
        var fd=new FormData();
        fd.append("examid",localStorage.getItem("examid"));
        fd.append("user_app_id",localStorage.getItem("user_app_id"));
        fd.append("enddatetime",localStorage.getItem("enddatetime"));
        var resp=await axios.post("https://skillupaviation.co.in/api/examQstAnswer",fd);
        var data=resp.data;
        console.log(data);

        setExamarr(data.examarr);
        setExamname(data.exam_name);
        setTotalqst(data.exam_total_qid);
    }

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgroup={
        margin: '10px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    // let [answerarr,setAnswerarr]=useState([]);
    // function changeanswer(e) {
    //     setAnswerarr([]);
    //     if (e.target.checked==true) {
    //         answerarr.push(e.target.value);
    //     } else {
    //         var index=answerarr.indexOf(e.target.value);
    //         answerarr.splice(index,1);
    //     }
    //     // alert(answerarr);
    // }

    let [exambtn,setExambtn]=useState(false);
    async function examsubmit() {        
        var all_ans=[];          
        var all_ans_count=0;                                          
        $("#answerRadios:checked").each(function() {
            all_ans.push($(this).val());
            ++all_ans_count;
        });

        // console.log(all_ans+'=======>'+all_ans_count);
        setExambtn(true);
        var fd=new FormData();
        fd.append("examid",localStorage.getItem("examid"));
        fd.append("user_app_id",localStorage.getItem("user_app_id"));
        fd.append("userpaymentid",localStorage.getItem("userpaymentid"));
        fd.append("totalqst",totalqst);
        fd.append("all_ans",all_ans);
        fd.append("all_ans_count",all_ans_count);
        var resp=await axios.post("https://skillupaviation.co.in/api/scoresubmit",fd);
        var data=resp.data;
        console.log(data);
        closeModal();
        setExambtn(false);

        let path = `/dashboard`;
        navigate(path);

    }


    let [isradio,setIsradio]=useState(false);
    const [datetimeinterval,setDateTimeInterval]=useState("");
    let interval = useRef();

    const startTimer = (endeddate) => {
        const coundownDate = new Date(endeddate).getTime();

        interval=setInterval(()=>{
            const now = new Date().getTime();
            const distance = coundownDate-now;

            // const days=Math.floor(distance / (1000*60*60*24));
            const hours=Math.floor((distance % (1000*60*60*24) / (1000*60*60)));
            const mins=Math.floor((distance % (1000*60*60)) / (1000*60));
            const secs=Math.floor((distance % (1000*60)) / 1000);

            if (distance < 0) {
                //stop timer code
                clearInterval(interval);
                setDateTimeInterval("Schedule End");
                setIsradio(true);
                // setIsskipbtn(true);


            } else {
                setDateTimeInterval(hours+"h "+mins+"m "+secs+"s");
                setIsradio(false);
                // setIsbtn(true);

                // logintokencheck();

            }
        
        }, 1)
    };

    useEffect(() => {
        exam_details();

        if (localStorage.getItem('examid')==null) {
            let path = `/dashboard`; 
            navigate(path);
        }
    }, []);

    return(
        <>
            <div id="content">   
                <div className="p-4 contentWrapper">
                    <div className="row">
                        <div className="col-lg-12 dasboard-wrapper">
                        {/* <h4 className="mb-3">Exam</h4> */}

                            <div className="bg-white p-4 col-md-8 mx-auto   ">
                                <div className="row font-weight-bold">
                                    <div className="col-md-12">

                                        {/* <div className="callnow">Time Left: <span id="starttimer"></span></div> */}

                                        <div className="row pb-3">
                                            <div className="col-md-12 text-center">
                                                <h3 className='text-primary'>{examname}</h3>
                                                <h6>Total Question : {totalqst}</h6>
                                                {startTimer(dateFormat(localStorage.getItem("enddatetime"), "mmmm dd, yyyy HH:MM:00"))}
                                                <h5 className='text-danger'>( Time Left: {datetimeinterval} )</h5>                            
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">

                                                {examarr.map((e,i=0)=>
                                                    
                                                <div className="card p-3 mb-3 bg-light" key={e.qid} disabled>                                                                
                                                    <h5 className="text-blue">Question ({++i}) {e.qid}: {e.question}</h5>
                                                    <div className='row'>
                                                        
                                                        {e.answer.map((a)=>
                                                        <div className='col-md-6' key={a.answer_slno}>
                                                            <div className="form-check text-green">
                                                                <input className="form-check-input" type="radio" name={"answerRadios"+e.qid} id="answerRadios" value={a.answer_slno} disabled={isradio} />
                                                                <label className="form-check-label" for={"answerRadios"+a.answer_slno}>
                                                                    {a.answer}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        )}
                                                        
                                                    </div> 

                                                </div>
                                                )}

                                            </div>
                                            
                                        </div>
                                            
                                            
                                        <div className="row">
                                            <div className="col-md-12 text-center mt-3">
                                                <input type="button" className="btn btn-danger text-center" id="btnsubmit_live" value="Exam Submit" onClick={()=>{
                                                    openModal();                                                    
                                                }} />
                                            </div>
                                        </div>

                                    </div> 

                                </div>                    
                            </div>
                            
                        </div>

                    </div>

                    <Modal
                        isOpen={modalIsOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel=""
                    >

                        <div className="main">
                            <div className="loaderWrapper">
                                Do you want to final submit this exam?
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center mt-3">
                                    <input type="button" className="btn btn-primary text-center" value="Final Submit" onClick={examsubmit} disabled={exambtn} />
                                    <input type="button" className="ml-2 btn btn-danger text-center" value="Close" onClick={()=>{
                                        closeModal();
                                    }} />
                                </div>
                            </div>
                        </div>                        

                    </Modal>
                    
                </div>
            </div>

        </>
    );
}

export default ExamContent;