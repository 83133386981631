// import React from 'react';

import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from "react-router-dom";
import axios from 'axios';

import $ from 'jquery';

function Navbar() {
    let navigate = useNavigate();

    function checkedLogin() {
            if (localStorage.getItem("user_app_id")==null) {
                let path = `/`; 
                navigate(path);
            }
    }

    const [user, setUser] = useState("");

    async function userprofile() {
        var fd=new FormData();
        fd.append("user_app_id",localStorage.getItem("user_app_id"));
        var resp=await axios.post("https://skillupaviation.co.in/api/showuserdata",fd);
        var data=resp.data;
        setUser(data.obj);
    }
    
    useEffect(() => {
        checkedLogin();
        userprofile();
    }, []);

    return(
        <>
           
           <nav className="xp-topnav navbar fixed-top navbar-expand navbar-dark">
            <button type="button" id="sidebarCollapse" className="btn btn-navbar" onClick={()=>{
            $('#sidebar').toggleClass('active');
            //$('.overlay').toggleClass('active');
            
            }}> <i className="glyphicon glyphicon-align-left"></i></button>
                <Link className="bg-white navbar-brand " to="/dashboard"><img src="images/logo.png" alt="" /> </Link>    
                  
            <ul className="navbar-nav ml-auto">        
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" id="userDropdown" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">					
                     <span className="avatar">
                        <img src="images/avatar.jpg" alt="user" className="avatar-img rounded-circle img-fluid" />
                    </span>
                    <span className="avatar-name pl-2">{user.full_name}</span> 
                     </a>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                        <Link className="dropdown-item" to="/change-password"><i className="glyphicon glyphicon-user mr-2"></i>Change Password</Link>
                        {/* <a className="dropdown-item" href="settings.html"><i className="glyphicon glyphicon-cog mr-2"></i>Settings</a>                         */}
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#" onClick={()=>{
                            localStorage.removeItem("user_app_id");
                            let path = `/`; 
                            navigate(path);
                        }}><i className="glyphicon glyphicon-off mr-2"></i>Logout</a>
                    </div>
                </li>
            </ul>
        </nav>

        </>
    );
}

export default Navbar;