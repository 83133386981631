
import React  from "react";




function AboutContent() {

  
    return(
        <>

<div>
    About
</div>

        </>
    );
}

export default AboutContent;