
import React, { useEffect, useState } from 'react';

import axios from 'axios';

import {useNavigate} from "react-router-dom";

import Userheader from '../inc/Userheader';

function StartexamdetailsContent() {  
    let navigate = useNavigate();

    const [examdetails, setExamdetails] = useState("");

    async function exam_details() {
        var fd=new FormData();
        fd.append("examid",localStorage.getItem("examid"));
        var resp=await axios.post("https://skillupaviation.co.in/api/startexamdetails",fd);
        var data=resp.data;
        setExamdetails(data.obj);
        console.log(data.obj);
    }

    async function startExam() {
        var fd=new FormData();
        fd.append("examid",localStorage.getItem("examid"));
        fd.append("user_app_id",localStorage.getItem("user_app_id"));
        var resp=await axios.post("https://skillupaviation.co.in/api/startexam",fd);
        var data=resp.data;

        localStorage.setItem("enddatetime",data.end_date_time);

        let path = `/exam`; 
        navigate(path);
        
    }

    useEffect(() => {
        exam_details();

        if (localStorage.getItem('examid')==null) {
            let path = `/dashboard`; 
            navigate(path);
        }
    }, []);

    return(
        <>
<div id="content">   
    <div className="p-4 contentWrapper">
    	<div className="row">
        	<div className="col-lg-12 dasboard-wrapper">
            <h4 className="mb-3">Start Exam Details</h4>

            <Userheader />

            
            <div className="bg-white p-4 w-100 container">
                <div className="row font-weight-bold">
                    <div className="col-md-12">

                    <h5 className='text-center mb-4 text-primary'> This is multiple choice phase exam set to test your knowledge</h5>
                    <div className='table-responsive'>
                    <table className="table table-hover">
                        {/* <thead>
                        <tr>
                            <th>Particular</th>
                            <th></th>
                        </tr>
                        </thead> */}
                        <tbody>
                            <tr>
                                <td>Exam Name</td>
                                <td>: {examdetails.exam_name}</td>                              
                            </tr>
                            <tr>
                                <td>Number of Questions</td>
                                <td>: {examdetails.exam_total_qst}</td>                              
                            </tr>
                            <tr>
                                <td>Total Marks</td>
                                <td>: {examdetails.exam_total_qst}</td>                              
                            </tr>
                            <tr>
                                <td>Pass Marks</td>
                                <td>: {examdetails.exam_passed}%</td>                              
                            </tr>
                            <tr>
                                <td>Time Duration</td>
                                <td>: {examdetails.exam_time}  Minutes</td>                              
                            </tr>
                            <tr>
                                <td>Question Type</td>
                                <td>: Multiple Choice</td>                              
                            </tr>                           
                        </tbody>
                    </table>
                    </div>

                    <h5 className='text-center mt-4'> 
                        <button className='btn btn-primary' onClick={startExam}>Start Exam</button>
                    </h5>

                    </div>                                        
                </div>                    
            </div>




            	
               
               
                
                
                
            </div>





        </div>
    	
    
       
      
    </div>
  </div>

        </>
    );
}

export default StartexamdetailsContent;