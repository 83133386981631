
import React, { useEffect, useState } from 'react';

import axios from 'axios';
import Userheader from '../inc/Userheader';


function PaymenthistoryContent() {  

    const [paymentlist, setPaymentlist] = useState([]);
    const [paymentlistcount, setPaymentlistcount] = useState("");

    async function paument_history() {
        var fd=new FormData();
        fd.append("user_app_id",localStorage.getItem("user_app_id"));
        var resp=await axios.post("https://skillupaviation.co.in/api/userpayment",fd);
        var data=resp.data;
        setPaymentlist(data.obj.find);
        setPaymentlistcount(data.obj.count);
    }

    useEffect(() => {
        paument_history();

        if (localStorage.getItem('examid')!=null) {
            localStorage.removeItem('examid');
            localStorage.removeItem("enddatetime");
            localStorage.removeItem("userpaymentid");
        }
    }, []);

    return(
        <>
<div id="content">   
    <div className="p-4 contentWrapper">
    	<div className="row">
        	<div className="col-lg-12 dasboard-wrapper">
            <h4 className="mb-3">Payment History</h4>


            <Userheader />

            

            
            <div className="bg-white p-4">
                <div className="row text-white font-weight-bold">
                    <div className="col-md-12">
                    <div className='table-responsive'>
                    <table class="table table-hover">
                        <thead>
                        <tr>
                            <th width={50}>SL</th>
                            <th>Exam Name</th>
                            <th>Payment Mode</th>
                            <th>Amount</th>
                            <th>Payment ID</th>
                            <th>Pay Date</th>
                            <th>Status</th>
                            <th width={150}>Pay Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        {paymentlistcount>0?
                           paymentlist.map((e,i=0)=>
                            <tr key={e.exam_id}>
                                <td>{++i}</td>
                                <td>{e.exam_name}</td>
                                <td>{e.payment_mode}</td>
                                <td>Rs.{e.order_amount}</td>
                                <td>{e.payment_id}</td>
                                <td>{e.payment_date!=null?e.payment_date:''}</td>
                                <td>
                                    {e.payment_status==0?
                                    <span className="text-secondary">Unpaid</span>
                                    :e.payment_status==1?
                                    <span className="text-success">Paid</span>
                                    :e.payment_status==3?
                                    <span className="text-danger">Failed</span>
                                    :
                                    ''
                                    }
                                </td>
                                <td>
                                    Rs.{e.payment_amount}  
                                </td>
                            </tr>
                            )
                        :
                        <tr colspan='7' className='text-center'></tr>
                        }
                        </tbody>
                    </table>
                    </div>

                    </div>                                        
                </div>                    
            </div>




            	
               
               
                
                
                
            </div>





        </div>
    	
    
       
      
    </div>
  </div>

        </>
    );
}

export default PaymenthistoryContent;