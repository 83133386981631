// import React from 'react';

import React, { useEffect,useState } from 'react';
import {useNavigate} from "react-router-dom";

import axios from 'axios';

import $ from 'jquery';

function Navbar2() {
    let navigate = useNavigate();

    function checkedLogin() {
            if (localStorage.getItem("user_app_id")==null) {
                let path = `/`; 
                navigate(path);
            }
    }

    const [user, setUser] = useState("");

    async function userprofile() {
        var fd=new FormData();
        fd.append("user_app_id",localStorage.getItem("user_app_id"));
        var resp=await axios.post("https://skillupaviation.co.in/api/showuserdata",fd);
        var data=resp.data;
        setUser(data.obj);
    }
    
    useEffect(() => {
        checkedLogin();
        userprofile();
    }, []);

    return(
        <>
           
           <nav className="xp-topnav navbar fixed-top navbar-expand navbar-dark">
                <div className="bg-white navbar-brand " onClick={()=>{
                $('#sidebar').toggleClass('active');
                //$('.overlay').toggleClass('active');
                
                }}><img src="images/logo.png" alt="" /></div>    
                  
            <ul className="navbar-nav ml-auto">        
                <li className="nav-item dropdown">
                    <div className="nav-link">					
                     <span className="avatar">
                        <img src="images/avatar.jpg" alt="user" className="avatar-img rounded-circle img-fluid" />
                    </span>
                    <span className="avatar-name pl-2 text-white">{user.full_name}</span> 
                     </div>
                 
                </li>
            </ul>
        </nav>

        </>
    );
}

export default Navbar2;