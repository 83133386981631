

import React, { useEffect, useState } from 'react';

import axios from 'axios';

import {useNavigate} from "react-router-dom";

import Userheader from '../inc/Userheader';

import dateFormat from 'dateformat';

import Supportmail from '../inc/Supportmail';

function DashboardContent() {

    let navigate = useNavigate();

    const [examlist, setExamlist] = useState([]);
    const [examlistcount, setExamlistcount] = useState("");

    const [load,setLoad]=useState(true);

    async function exam_list() {
        setLoad(true);
        var fd=new FormData();
        fd.append("user_app_id",localStorage.getItem("user_app_id"));
        var resp=await axios.post("https://skillupaviation.co.in/api/userexamlist",fd);
        var data=resp.data;
        setExamlist(data.obj.find);
        setExamlistcount(data.obj.examcount);
        setLoad(false);
    }

    useEffect(() => {
        exam_list();

        if (localStorage.getItem('examid')!=null) {
            localStorage.removeItem('examid');
            localStorage.removeItem("enddatetime");
            localStorage.removeItem("userpaymentid");
        }
        
    }, []);
  
    return(
        <>

<div id="content">
    
    
    
    
    <div className="p-4 contentWrapper">
    	<div className="row">
        	<div className="col-lg-12 dasboard-wrapper">
            <h4 className="mb-3">Dashboard</h4>


            <Userheader />

            

            
            <div className="bg-white p-4">
                <div className="row text-white font-weight-bold">
                    <div className="col-md-12">
                    <div className='table-responsive'>
                    <table class="table table-hover">
                        <thead>
                        <tr>
                            <th  width={50}>SL</th>
                            <th>Exam name</th>
                            <th>Exam Under Shedule Date</th>
                            <th  width={150}>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {examlistcount>0?
                            examlist.map((e,i=0)=>  
                            <tr key={e.exam_id}>
                                <td>{++i}</td>
                                <td>{e.exam_name}</td>
                                <td>{e.exam_phase_allot_date}</td>
                                <td>{
                                    
                                    // (new Date(dateFormat(e.exam_phase_allot_date, "mmmm dd, yyyy HH:MM:00")).getTime())-(new Date().getTime())<=0?
                                    // (new Date(dateFormat(e.exam_phase_allot_date, "mmmm dd, yyyy HH:MM:00")).getTime())-(new Date().getTime())>0?
                                        
                                    e.payment_status==1?
                                        <button className="btn btn-primary" onClick={()=>{
                                            localStorage.setItem('examid',e.exam_id);
                                            localStorage.setItem("userpaymentid",e.id);
                                            console.log('===>',localStorage.getItem('examid'));
                                            let path = `/startexamdetails`; 
                                            navigate(path);
                                        }}>Go Exam</button>
                                        :
                                        <button className="btn btn-danger" disabled>Closed Exam</button>
                                    // :
                                    // <button className="btn btn-secondary" disabled>Schedule End</button>
                                    }
                                </td>
                            </tr>
                            )
                        :examlistcount==0?
                            load?
                            <tr><td className='text-center fw-bold text-danger' colSpan={4}>Please Wait Data Loading...</td></tr>
                            :
                            <tr><td className='text-center fw-bold' colSpan={4}>--No Exam Found--</td></tr>
                        :
                        <tr></tr>
                        }
                    
                        </tbody>
                    </table>

                    </div>

                    {/* <h2 className="text-danger text-center"></h2> */}

                    </div>                                        
                </div>                    
            </div>


            <Supportmail />

            	
               
               
                
                
                
            </div>





        </div>
    	
    
       
      
    </div>
  </div>

        </>
    );
}

export default DashboardContent;