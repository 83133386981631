import React  from 'react';

import MyprofileContent from "../content/MyprofileContent";

import Navbar from "../inc/Navbar";
import Sidebar from "../inc/Sidebar";
import Footer from "../inc/Footer";

function Myprofile() {
    return(
        <>
        <Navbar />       
        <div class="wrapper">            
            <Sidebar />
            <MyprofileContent />
        </div>
        <Footer />    

       
        </>
    );
}

export default Myprofile;