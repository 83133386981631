
import React, { useEffect, useState } from 'react';

import axios from 'axios';

import Supportmail from '../inc/Supportmail';

function ChangePasswordContent() {

    const [user, setUser] = useState("");
    
    let [password,setPassword]=useState("");
    let [password2,setPassword2]=useState("");

    let [passworderr,setPassworderr]=useState("");

    let [msg,setMsg]=useState("");
    let [msgcode,setMsgcode]=useState("");
    let [msgcolor,setMsgcolor]=useState("");

    let [isbtn,setIsbtn]=useState(true);

    async function updatepassword() {
        setPassworderr("");
        var flag=1;
        if (password.length>0 && password2.length>0) {
           if (password.length<6 || password2.length<6 && password!=password2) {
                  setPassworderr("Required 6-Alfanumeric Password");
                  flag=0;
           } else if (password.length>=6 && password2.length>=6 && password!=password2) {
                  setPassworderr("Password does not match");
                  flag=0;
           }
        } else {
                  setPassworderr("Required 6-Alfanumeric Password");
                  flag=0;
        }
        if (flag==1) {
           var fd=new FormData();
  
           fd.append("password",password);
           fd.append("user_app_id",localStorage.getItem("user_app_id"));
           var resp=await axios.post("https://skillupaviation.co.in/api/updatepassword",fd);
           var data=resp.data;
           setMsg(data.msg);
           setMsgcode(data.msgcode);
           setMsgcolor(data.msgcolor);
  
           if (data.msgcode==200) {
               setIsbtn(true);
               setPassword("");
               setPassword2("");
  
               setPassworderr("");

           }
        }
    }

    useEffect(() => {

        if (localStorage.getItem('examid')!=null) {
            localStorage.removeItem('examid');
            localStorage.removeItem("enddatetime");
            localStorage.removeItem("userpaymentid");
        }
        
    }, []);

    return(
        <>
<div id="content">   
    <div className="p-4 contentWrapper">
    	<div className="row">
        	<div className="col-lg-8 dasboard-wrapper">
            {/* <h4 className="mb-3 clearfix">Change Password </h4> */}
            {/* <button className="btn btn-success float-right">Edit</button> */}
           

            <div className="bg-white p-4 text-center ">
                <div className="row">
                    <div className="col-md-8 pb-5">
                        <h4 className="text-center"><img src="images/logo.png" alt="" /></h4>
                    </div>
                    <div className="col-md-12 pb-2 fw-bold text-center">:: UPDATE PASSWORD ::</div>
                    <div className={msgcolor}>{msg}</div>
                    <div className='text text-danger py-2'>{passworderr}</div>
                    <div className="form-group pt-2 col-lg-12">
                        <input type="text" className="form-control" placeholder='Minimum 6 digits password' onChange={(e)=>{
                            setPassword(e.target.value);
                        }} />
                    </div>
                    <div className="form-group col-lg-12">
                        <input type="password" className="form-control" maxlength="10" placeholder='Re-type password' onChange={(e)=>{
                            setPassword2(e.target.value);
                        }} />
                       
                    </div>
                    
                    <div className="col-lg-12 text-center">
                        <input type="hidden" id="user_app_id" value="" />
                        <button id="btnupdateprofile" className="btn btn-success fw-bold" onClick={updatepassword}>UPDATE</button>
                        
                        
                    </div>









                    {/* <div className="col-md-6 pb-3">
                        assa
                    </div> */}

                    



                </div>                    
            </div>


            {/* <div className="bg-white p-4 mt-4">
                <div className="row font-weight-bold">
                    <div className="col-md-12 text-center">
                        <h3 className="text-primary">Please contact by our support - <a href="mailto:support@gmail.com">support@gmail.com</a></h3>
                    </div>
                </div>
            </div> */}

            <Supportmail />

            	
               
               
                
                
                
            </div>





        </div>
    	
    
       
      
    </div>
  </div>

        </>
    );
}

export default ChangePasswordContent;