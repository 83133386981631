// import React  from "react";

import React, { useEffect, useState } from 'react';
import {useNavigate} from "react-router-dom";
import axios from 'axios';

function IndexContent() {
  const [userid, setUserid] = useState("");
  const [password, setPassword] = useState("");
  const [isuserid, setIsuserid] = useState(false);
  const [ispassword, setIspassword] = useState(false);

  const [msg, setMsg] = useState("");
  const [msgcolor, setMsgcolor] = useState("");

  
  let navigate = useNavigate();

  async function userLogin() {  
    var flag = 1
    if (userid.length==0 || userid.trim()=="") {
      // setUsernameerr("Required User ID");
      flag=0;
      setIsuserid(true);
    }
    if (password.length==0 || password.trim()=="") {
        // setPassworderr("Required Password");
        flag=0;
        setIspassword(true);
    }

    if (flag==1) {
      
      var fd=new FormData();
      fd.append("userid",userid);
      fd.append("password",password);
      var resp=await axios.post("https://skillupaviation.co.in/api/authlogin",fd);
      // var resp=await axios.get("https://skillupaviation.co.in/api/showuserdata");
      var data=resp.data;

      // console.log("=========>",data)
      

      if (data.statusCode==200) {
            localStorage.setItem("user_app_id",data.user_app_id);
            let path = `/dashboard`; 
            navigate(path);
      } else {
        setMsg(data.msg);
        setMsgcolor(data.msgcolor);
        setTimeout(()=>{
          setMsg("");
          setMsgcolor("");
        },2000);
        
      }
      
    }



  }

  function checkedLogin() {
    console.log(localStorage.getItem("user_app_id"))
    if (localStorage.getItem("user_app_id")!=null) {
        let path = `/dashboard`; 
        navigate(path);
    }
}

  useEffect(() => {
    checkedLogin();

    if (localStorage.getItem('examid')!=null) {
      localStorage.removeItem('examid');
    }
}, []);

  // https://skillupaviation.co.in/api/authlogin

    return(
        <>


<div className="loginBoxWrap">
  <div className="loginBox">
  	<div className="bg_white">
    <h2>Candidate Login</h2>
    <div className="loginBoxContener p-4">
      {/* <p>Use a valid username and password to gain access to the candidate backend.</p> */}

        {msg.length>0?<div className={msgcolor}>{msg}</div>:''}
        <div className="form-group row">
          {/* <label for="inputEmail3" className="col-sm-12 col-form-label">Application ID/Phone No</label> */}
          <div className="col-sm-12">
            <input type="text" className="form-control" id="inputEmail3" placeholder="Application ID/Phone No" onChange={(e)=>{
              setUserid(e.target.value);
              if (e.target.value.length>0) {
                setIsuserid(false);
              }
            }}  />
            {isuserid?<div className="text text-danger">required</div>:''}
          </div>
        </div>
        <div className="form-group row">
          {/* <label for="inputPassword3" className="col-sm-3 col-form-label">Password</label> */}
          <div className="col-sm-12">
            <input type="password" className="form-control" id="inputPassword3" placeholder="Password" onChange={(e)=>{
              setPassword(e.target.value);
              if (e.target.value.length>0) {
                setIspassword(false);
              }
            }}  />
          {ispassword?<div className="text text-danger">required</div>:''}      
          </div>
        </div>
        {/* <div className="form-group row">
          <div className="col-sm-9 offset-3">
            <div className="form-check small">
              <input className="form-check-input" type="checkbox" id="gridCheck1" />
              <label className="form-check-label " for="gridCheck1"> Remember Me / <a href="http://www.google.com" target="_blank" className="font-weight-bold">Forgot Password ?</a> </label>
            </div>
          </div>
        </div> */}
        <div className="form-group row">
          <div className="col-sm-10 offset-3">
            <div className="form-check">
              <button type='button' className="btn btn-primary" onClick={userLogin}>Submit</button>
            </div>
          </div>
        </div>


    </div>
    </div>
   {/* <div className="copyright mt-2">© Copyright 2024 <a href="http://www.google.com" target="_blank"><u>ABC Company</u></a>. All Rights Reserved.</div> */}
   
  </div>   
</div>

        
         </>

    );
}

export default IndexContent;