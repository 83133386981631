import React  from 'react';

import ExamContent from "../content/ExamContent";

import Navbar2 from "../inc/Navbar2";

function Exam() {
    return(
        <>
        <Navbar2 />       
        <div class="wrapper">          

            <ExamContent />
        </div>
       
        </>
    );
}

export default Exam;