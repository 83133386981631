
import React, { useEffect, useState } from 'react';

import axios from 'axios';

import Supportmail from '../inc/Supportmail';

function MyprofileContent() {

    const [user, setUser] = useState("");
    const [userextracurricular, setUserextracurricular] = useState("");
    let [extracurricular, setExtracurricular] = useState([]);
    let [userextracurriculararr, setUserextracurriculararr] = useState("");
    let [userextracurriculararrcount, setUserextracurriculararrcount] = useState("");

    let [userlangknown, setUserlangknown] = useState("");
    let [langknown, setLangknown] = useState([]);
    let [userlangknownarr, setUserlangknownarr] = useState("");
    let [userlangknownarrcount, setUserlangknownarrcount] = useState("");

    let [name,setName]=useState("");
    let [mobile,setMobile]=useState("");
    let [email,setEmail]=useState("");
    let [joblocation,setJoblocation]=useState("");
    let [skin,setSkin]=useState("");
    let [height,setHeight]=useState("");
    let [weight,setWeight]=useState("");
    let [dob,setDob]=useState("");
    let [passport,setPassport]=useState("");
    let [qualification,setQualification]=useState("");
    let [gender,setGender]=useState("");
    let [mothertongue,setMothertongue]=useState("");
    let [addr1,setAddr1]=useState("");
    let [addr2,setAddr2]=useState("");
    let [city,setCity]=useState("");
    let [pincode,setPincode]=useState("");
    let [photo,setPhoto]=useState(null);

    let [extracurriculararr,setExtracurriculararr]=useState([]);
    let [langknownarr,setLangknownarr]=useState([]);

    async function editProfile() {
      var fd=new FormData();
      fd.append("user_app_id",localStorage.getItem("user_app_id"));
      var resp=await axios.post("https://skillupaviation.co.in/api/editprofile",fd);
      var data=resp.data;
      console.log(data);

      setUser(data.user);
      setName(data.user.full_name);
      setMobile(data.user.user_phone);
      setEmail(data.user.user_email);
      setJoblocation(data.user.user_job_location);
      setSkin(data.user.user_skin);
      setHeight(data.user.user_height);
      setWeight(data.user.user_weight);
      setDob(data.user.user_dob);
      setPassport(data.user.user_passport);
      setQualification(data.user.user_qualification);
      setGender(data.user.user_gender);
      setMothertongue(data.user.user_mother_tongue);
      setAddr1(data.user.user_addr1);
      setAddr2(data.user.user_addr2);
      setCity(data.user.user_city);
      setPincode(data.user.user_pincode);
      setPhoto(data.user.user_pic);

      setUserextracurricular(data.userextracurricular);
      setExtracurricular(data.extra_curricular);
      setUserextracurriculararr(data.user_extra_curricular_arr); // Update to DB
      setExtracurriculararr(data.user_extra_curricular_arr);
    //   setUserextracurriculararrcount(data.user_extra_curricular_arr_count);

      setUserlangknown(data.userlangknown);
      setLangknown(data.language_known);
      setUserlangknownarr(data.user_lang_known_arr); // Update to DB
      setLangknownarr(data.user_lang_known_arr);
    //   setUserlangknownarrcount(data.user_extra_curricular_arr_count);
    }

    

    function changestream(e) {
        if (e.target.checked==true) {
            extracurriculararr.push(e.target.value);
        } else {
            var index=extracurriculararr.indexOf(e.target.value);
            extracurriculararr.splice(index,1);
        }
    }

    function changelanguage(e) {
        if (e.target.checked==true) {
            langknownarr.push(e.target.value);
        } else {
            var index=langknownarr.indexOf(e.target.value);
            langknownarr.splice(index,1);
        }
    }

    let [optradiochk,setOptradiochk]=useState(false);
    let [optradiochkerr,setOptradiochkerr]=useState(false);

    async function updateData() {
        if (optradiochk==false) {
            setOptradiochkerr(true);
        } else {
            setOptradiochkerr(false);
            var fd=new FormData();
            fd.append("user_app_id",localStorage.getItem("user_app_id"));
            fd.append("name",name);
            fd.append("mobile",mobile);
            fd.append("email",email);
            fd.append("joblocation",joblocation);
            fd.append("skin",skin);
            fd.append("height",height);
            fd.append("weight",weight);
            fd.append("dob",dob);
            fd.append("passport",passport);
            fd.append("qualification",qualification);
            fd.append("gender",gender);
            fd.append("mothertongue",mothertongue);
            fd.append("addr1",addr1);
            fd.append("addr2",addr2);
            fd.append("city",city);
            fd.append("pincode",pincode);
            fd.append("city",city);
            fd.append("photo",photo);
            fd.append("extracurriculararr",extracurriculararr);
            fd.append("langknownarr",langknownarr);
            var resp=await axios.post("https://skillupaviation.co.in/api/updateprofile",fd);
            var data=resp.data;
            alert(data.msg);
            editProfile();
        }
        
    }

    useEffect(() => {
        editProfile();

        if (localStorage.getItem('examid')!=null) {
            localStorage.removeItem('examid');
            localStorage.removeItem("enddatetime");
            localStorage.removeItem("userpaymentid");
        }
    }, []);


    return(
        <>
<div id="content">   
    <div className="p-4 contentWrapper">
    	<div className="row">
        	<div className="col-lg-12 dasboard-wrapper">
            <h4 className="mb-3 clearfix">My Profile </h4>
            {/* <button className="btn btn-success float-right">Edit</button> */}
           

            <div className="bg-white p-4">
                <div className="row">
                    <div className="col-md-12 pb-5">
                        <h4 className="text-center"><img src="images/logo.png" alt="" /></h4>
                    </div>
                    <div className="col-md-12 pb-3 fw-bold">Applicant Information :</div>

                    <div className="form-group col-lg-12">
                        <input type="text" className="form-control" defaultValue={user.full_name} placeholder='User Name' onChange={(e)=>{
                            setName(e.target.value);
                        }} />
                    </div>
                    <div className="form-group col-lg-6">
                        <input type="tel" className="form-control" maxlength="10" defaultValue={user.user_phone} placeholder='Phone Number' disabled onChange={(e)=>{
                            setMobile(e.target.value);
                        }} />
                       
                    </div>
                    {/* <div className="form-group col-lg-6">
                        <input type="text" className="form-control" id="mobile_alt" name="mobile_alt" maxlength="10" value={user.user_phone_alt} placeholder='Alternet Phone Number' />                                   
                    </div> */}
                    <div className="form-group col-lg-6">
                        <input type="email" className="form-control" defaultValue={user.user_email} placeholder='Email ID' disabled onChange={(e)=>{
                            setEmail(e.target.value);
                        }} />
                        
                    </div>
                    {/* <div className="form-group col-lg-6">
                        <input type="email" className="form-control" id="email_alt" name="email_alt" value={user.user_email_alt} placeholder='Alernet Email ID' />                       
                    </div> */}

                    <div className="form-group col-lg-6">
                        <input type="text" className="form-control" defaultValue={user.user_job_location} placeholder='Job Location'   onChange={(e)=>{
                            setJoblocation(e.target.value);
                        }} />                        
                    </div>

                    <div className="form-group  input-group col-lg-6">
                        <select className="form-control" value={skin} onChange={(e)=>{
                            setSkin(e.target.value);
                        }}>
                            <option value="">--Applicant Skin Complextion--</option>                        
                            <option value="Fair" >Fair</option>
                            <option value="Medium">Medium</option>
                            <option value="Dark">Dark</option>
                        </select>
                    </div>


                    <div className="form-group  input-group col-lg-6">
                        <input type="text" className="form-control" placeholder='Height' defaultValue={user.user_height} onChange={(e)=>{
                            setHeight(e.target.value);
                        }} />
                        <div className="input-group-prepend"> <span className="input-group-text">CM</span> </div>                             
                    </div>

                    <div className="form-group  input-group col-lg-6">                    
                        <input type="text" className="form-control" placeholder='Weight' defaultValue={user.user_weight} onChange={(e)=>{
                            setWeight(e.target.value);
                        }} />
                            <div className="input-group-prepend"> <span className="input-group-text">KG</span> </div>                        
                    </div>
                    
                    <div className="form-group col-lg-12"><hr /></div>

                    <div className="form-group col-lg-6">
                        <label >Date of Birth: </label>
                        <input placeholder="dd-mm-yyyy" className="textbox-n form-control" defaultValue={user.user_dob} type="date"  onChange={(e)=>{
                            setDob(e.target.value);
                        }}  />                        
                    </div> 

                    <div className="form-group col-lg-6">
                        <label>Do You Have Passport? </label>
                        <select className="form-control" value={passport} onChange={(e)=>{
                            setPassport(e.target.value);
                        }} >
                            <option value="">--Having Passport?--</option>                                               
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>                                           
                        </select>
                        {/* <div className="cpassporterr"><i className='fa fa-star' ></i> Specify Passport</div> */}
                    </div>


                    <div className="form-group col-lg-12"><hr /></div>

                    <div className="col-12">
                        <label>Educational Qualification : </label>
                    </div>

                    <div className="form-group col-lg-12">
                        <select className="form-control" value={qualification}  onChange={(e)=>{
                            setQualification(e.target.value);
                        }}>
                        <option value="">----Select Qualification----</option>
                            <option value="Non-Matriculated">Non Metriculation</option>
                            <option value="Matriculated">Matriculated</option>
                            <option value="Higher Secondary">Higher Secondary</option>
                            <option value="Under Graduate">Under Graduate</option>
                            <option value="Graduate">Graduate</option>
                            <option value="Post Graduate">Post Graduate</option> 

                           
                        </select>
                        {/* <div className="ceduqerr"><i className='fa fa-star' ></i> Select Your Qualification</div> */}
                    </div>

                    <div className="form-group col-lg-12"><hr /></div>

                    <div className="col-12">
                        <label>Extra Knowledge : </label>
                    </div>

                    <div className="form-group col-lg-12">
                        {userextracurricular.findcount==0?
                        
                        <div>
                            {extracurricular.map((e)=>
                                
                                
                            <div className="form-check form-check-inline" key={e.id}>                    
                                <input className="form-check-input" type="checkbox" id={"extra_curricular"+e.id} name="extra_curricular" value={e.extra_curricular_name} onChange={changestream} />
                                <label className="form-check-label" for={"extra_curricular"+e.id}>{e.extra_curricular_name}</label>                        
                            </div>

                            
                            )}
                            
                        </div>
                        :
                        <div>
                            {extracurricular.map((e)=>
                                // userextracurriculararr.includes(e.extra_curricular_name)?
                                userextracurriculararr.indexOf(e.extra_curricular_name) !== -1?
                                <div className="form-check form-check-inline" key={e.id}>                    
                                    <input className="form-check-input" type="checkbox" id={"extra_curricular"+e.id} name="extra_curricular" value={e.extra_curricular_name} defaultChecked onChange={changestream} />
                                    <label className="form-check-label" for={"extra_curricular"+e.id}>{e.extra_curricular_name}</label>                        
                                </div>
                                :
                                <div className="form-check form-check-inline" key={e.id}>                    
                                    <input className="form-check-input" type="checkbox" id={"extra_curricular"+e.id} name="extra_curricular" value={e.extra_curricular_name} onChange={changestream} />
                                    <label className="form-check-label" for={"extra_curricular"+e.id}>{e.extra_curricular_name}</label>                        
                                </div>
                            )}
                        </div>
                        }

                    </div>

                    

                    <div className="form-group col-lg-12"><hr /></div>

                    <div className="col-12">
                        <label >Select Mother Tongue : </label>
                    </div>

                    <div className="form-group col-lg-12">
                        <select className="form-control"  value={mothertongue} onChange={(e)=>{
                            setMothertongue(e.target.value)
                        }} >
                            <option value="">----Select Mother Tongue----</option>
                            <option value="Hindi">Hindi</option>
                            <option value="English">English</option>
                            <option value="Bengali">Bengali</option>
                            <option value="Marathi">Marathi</option>
                            <option value="Telugu">Telugu</option>
                            <option value="Tamil">Tamil</option>
                            <option value="Gujarati">Gujarati</option>
                            <option value="Urdu">Urdu</option>
                            <option value="Kannada">Kannada</option>
                            <option value="Odia">Odia</option>
                            <option value="Malayalam">Malayalam</option>
                            <option value="Punjabi">Punjabi</option>
                            <option value="Sanskrit">Sanskrit</option>
                        </select>

                        
                    </div> 

                    <div className="form-group col-lg-12"><hr /></div>

                    <div className="col-12">
                        <label>Languages Known : </label>
                    </div>

                    
                    <div className="form-group col-lg-12">
                        {userlangknown.findcount==0?
                        
                        <div>
                            {langknown.map((e)=>
                                
                                
                            <div className="form-check form-check-inline" key={e.id}>                    
                                <input className="form-check-input" type="checkbox" id={"lang_known"+e.id} name="lang_known" value={e.lang_known_name} onChange={changelanguage} />
                                <label className="form-check-label" for={"lang_known"+e.id}>{e.lang_known_name}</label>                        
                            </div>

                            
                            )}
                            
                        </div>
                        :
                        <div>
                            {langknown.map((e)=>
                                userlangknownarr.indexOf(e.lang_known_name) !== -1?
                                <div className="form-check form-check-inline" key={e.id}>                    
                                    <input className="form-check-input" type="checkbox" id={"lang_known"+e.id} name="lang_known" value={e.lang_known_name} defaultChecked onChange={changelanguage} />
                                    <label className="form-check-label" for={"lang_known"+e.id}>{e.lang_known_name}</label>                        
                                </div>
                                :
                                <div className="form-check form-check-inline" key={e.id}>                    
                                    <input className="form-check-input" type="checkbox" id={"lang_known"+e.id} name="lang_known" value={e.lang_known_name} onChange={changelanguage} />
                                    <label className="form-check-label" for={"lang_known"+e.id}>{e.lang_known_name}</label>                        
                                </div>
                            )}
                        </div>
                        }

                    </div>


                        <div className="form-group col-lg-12"><hr /></div>

                        <div className="col-12">
                            <label className="fw-bold">Select Gender : </label>
                        </div>

                        <div className="form-group col-lg-12">
                            <select className="form-control" value={gender} onChange={(e)=>{
                                setGender(e.target.value)
                            }} >
                                <option value="">----Select Gender----</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>     
                            </select>
                            {/* <div className="cgendererr"><i className='fa fa-star'></i> Select Gender</div> */}
                        </div>

                        <div className="form-group col-lg-12"><hr /></div>

                        <div className="col-12">
                            <label className="fw-bold">Applicant Residential Address : </label>
                        </div>

                        <div className="form-group col-lg-12">
                            <input type="text" className="form-control" placeholder="Address Line 1" defaultValue={user.user_addr1} onChange={(e)=>{
                            setAddr1(e.target.value)
                        }} />
                            {/* <div className="caddrline1err"><i className='fa fa-star'></i> Address Line 1 Required</div> */}
                        </div>

                        <div className="form-group col-lg-12">
                            <input type="text" className="form-control" placeholder="Address Line 2 (Optional)" defaultValue={user.user_addr2} onChange={(e)=>{
                            setAddr2(e.target.value)
                        }} />   
                        </div>
    
                        <div className="form-group col-lg-6">
                            <input type="text" className="form-control" placeholder="Applicant City" defaultValue={user.user_city} onChange={(e)=>{
                            setCity(e.target.value)
                        }} />
                            {/* <div className="ccityerr"><i className='fa fa-star'></i> City Required</div> */}
                        </div>

                        <div className="form-group col-lg-6">
                            <input type="text" className="form-control" maxlength="6" placeholder="Area Pin Code" defaultValue={user.user_pincode} onChange={(e)=>{
                            setPincode(e.target.value)
                        }} />
                            {/* <div className="cpinerr"><i className='fa fa-star' ></i> Pincode Required</div> */}
                        </div>
                                            
                        <div className="form-group col-lg-12"><hr /></div>

                        <div className="form-group col-lg-12">
                            <label className="fw-bold">Upload Passport Size Photo : [Photo Size width 200px &amp; height 245px] </label>                           
                            <input type="file" accept=".jpg,.jpeg,.png" onChange={(e)=>{
                                setPhoto(e.target.files[0])
                            }} />
                            {photo!=null?<div className='pt-3'><img src={"https://skillupaviation.co.in/uploads/"+photo} style={{width: '100px'}} /></div>:''}
                            {/* <input type="hidden" id="upload_photo_name" />
                            <div className="uploadphotoerr"><i className='fa fa-star' ></i> Upload Photo</div>
                            <div className="uploadphotosuccess"><i className="fa fa-check"></i> Photo Successfully Uploaded!</div> */}
                        </div>
                                        
                        <div className="checkbox form-group col-lg-12 text-center">
                            <label><input type="checkbox" id="optradio" name="optradio" onChange={(e)=>{
                                if (e.target.checked==true) {
                                    setOptradiochk(true);
                                } else {
                                    setOptradiochk(false);
                                }
                            }}/> I hereby declare that all the above information is correct and accurate. I solemnly declare that all the information furnished in this document is free of errors to the best of my knowledge and also I have read and agree to the terms of use.</label>
                        {optradiochkerr?<div className='text-danger small pt-2'>please check above the terms &amp; condiions checkbox</div>:''}
                        </div>


                        <div className="col-lg-12 text-center">
                            <input type="hidden" id="user_app_id" value="" />
                            <button id="btnupdateprofile" className="btn btn-success fw-bold" onClick={updateData}>UPDATE</button>

                            <div className="success"></div>                            
                            <div className="allfielderr" >[ Note: <i className="fa fa-star"></i> - fields are mendatory ]</div>
                        </div>









                    {/* <div className="col-md-6 pb-3">
                        assa
                    </div> */}

                    



                </div>                    
            </div>


            {/* <div className="bg-white p-4 mt-4">
                <div className="row font-weight-bold">
                    <div className="col-md-12 text-center">
                        <h3 className="text-primary">Please contact by our support - <a href="mailto:support@gmail.com">support@gmail.com</a></h3>
                    </div>
                </div>
            </div> */}

            <Supportmail />

            	
               
               
                
                
                
            </div>





        </div>
    	
    
       
      
    </div>
  </div>

        </>
    );
}

export default MyprofileContent;