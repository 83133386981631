import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Userheaderjs() {

    const [user, setUser] = useState("");

    async function showuser() {
        var fd=new FormData();
        fd.append("user_app_id",localStorage.getItem("user_app_id"));
        var resp=await axios.post("https://skillupaviation.co.in/api/showuserdata",fd);
        var data=resp.data;
        setUser(data.obj);
    }

    useEffect(() => {
        showuser();
    }, []);

    return(
        <>
           
           <div className="bg-white p-4 mb-4">
                <div className="row text-white font-weight-bold">
                    <div className="col-sm-4">
                          <div className="rounded bg-primary p-3 d-block">
                            <h4 className=" h5  font-weight-bold">Applicant Name</h4>
                            <p className="m-0">{user.full_name}</p>
                           </div>    
                    </div>

                     <div className="col-sm-4">
                          <div className="rounded bg-secondary p-3 d-block">
                            <h4 className="text-white h5 font-weight-bold">Applicant ID:</h4>
                            <p className="m-0">{user.user_app_id}</p>
                           </div>    
                    </div> 

                     <div className="col-sm-4">
                          <div className="rounded bg-success p-3 d-block">
                            <h4 className="text-white h5 font-weight-bold">Applicant Status: </h4>
                            <p className="m-0">{user.reg_status==0?"Under Review":user.reg_status==1?"Active":user.reg_status==2?"Inactive":""}</p>
                           </div>    
                    </div>                     
                </div>                    
            </div>

        </>
    );
}

export default Userheaderjs;