import React  from 'react';

import ChangePasswordContent from "../content/ChangePasswordContent";

import Navbar from "../inc/Navbar";
import Sidebar from "../inc/Sidebar";
import Footer from "../inc/Footer";

function ChangePassword() {
    return(
        <>
        <Navbar />       
        <div class="wrapper">            
            <Sidebar />
            <ChangePasswordContent />
        </div>
        <Footer />    

       
        </>
    );
}

export default ChangePassword;