import React from 'react';


function Supportmail() {
    
    return(
        <>

        <div className="bg-white p-4 mt-4">
            <div className="row font-weight-bold">
                <div className="col-md-12 text-center">
                    <h5 className="text-primary">Please contact by our support - <a href="mailto:official@skillupaviation.co.in">official@skillupaviation.co.in</a></h5>
                </div>
            </div>
        </div>
        
        </>
    );
}

export default Supportmail;